import "./Events.css";
import eventoImersivo from "../../assets/img/evento-imersivo.jpg";
import eventoOnline from "../../assets/img/evento-online.jpg";
import eventoPresencial from "../../assets/img/evento-presencial.jpg";

export default function Events() {
    return (
        <div className="events">
            <div className="row event-content">
                <h4>Evento</h4>
                <div className="col">
                    {/* Todo: transformar em componente */}
                    <div className="event">
                        <div className="image">
                            <a href="https://igrejasobrenatural.inpeaceapp.com/event/24324/" target="_blank">
                                <img src={eventoPresencial} />
                            </a>
                        </div>
                        <p>
                            <span className="title">
                                Congresso presencial
                            </span>
                            <span className="info">
                                Ingressos esgotados
                            </span>
                            <span className="price">
                                R$ 100,00
                            </span>
                        </p>
                    </div>
                </div>                
                <div className="col">
                    <div className="event">
                        <div className="image">
                            <a href="https://igrejasobrenatural.inpeaceapp.com/event/24324/" target="_blank">
                                <img src={eventoOnline} />
                            </a>
                        </div>
                        <p>
                            <span className="title">
                                Congresso imersão 360 (sem óculos)
                            </span>
                            <span className="info">

                            </span>
                            <span className="price">
                                R$ 100,00
                            </span>
                        </p>
                    </div>
                </div>
                <div className="col">
                    <div className="event">
                        <div className="image">
                            <a href="https://igrejasobrenatural.inpeaceapp.com/event/24324/" target="_blank">
                                <img src={eventoImersivo} />
                            </a>
                        </div>
                        <p>
                            <span className="title">
                                Congresso imersão 360 (com óculos)
                            </span>
                            <span className="info">

                            </span>
                            <span className="price">
                                R$ 130,00
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}