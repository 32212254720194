
import "./Footer.css";

export default function Footer() {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col text-footer">
                        Igreja Batista Sobrenatural<br />
                        Área Especial 1, Lote 11 Setor Central Lado Oeste - Gama, Brasília - DF, 72405-610 <br />
                        (61) 3385-0035
                    </div>
                    <div className="col text-right text-footer">
                        <br />
                        2022 - All Rights Reserved
                    </div>
                </div>
            </div>
        </footer>
    );
}