
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo-ultimato.png";
import "./Menu.css";

export default function Menu() {
    const [toogle, setToogle] = useState(false);

    return (
            <nav className="navbar navbar-expand-lg">
                <div className="container">
                    <button onClick={() => setToogle(!toogle)} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <Link to="/" className="navbar-brand">
                        <img src={logo} className="App-logo" alt="logo" />
                    </Link>
                    <div className={`collapse ${toogle ? "show" : ""} navbar-collapse`} id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item active">
                                <Link to="/soon" className="nav-link">Sobre</Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link to="/soon" className="nav-link">Line Up</Link>
                            </li>                     */}
                            <li className="nav-item">
                                <Link to="/soon" className="nav-link">Programação</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/soon" className="nav-link">Experiência online</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/purchase" className="nav-link">Comprar ingressos</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
    );
}