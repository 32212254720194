import { Link } from "react-router-dom";
import "./Purchase.css";

export default function Purchase() {
    return (
        <div className="purchase">
            <div className="container">
                <p>
                    Compre o seu <br />
                    ingresso agora
                </p>
                
                <a href="https://igrejasobrenatural.inpeaceapp.com/event/24324/" target="_blank">
                    Clique aqui
                </a>
            </div>
        </div>
    );
}