import "./LineUp.css";
import title from "../../assets/img/line-up-title.png";

export default function LineUp() {
    return (
        <div className="line-up">
            <div className="container">
                <p>
                    <img src={title} className="title" />
                </p>
                <p className="text">
                    Em 2022 estamos chamando você para ser capacitado para tomar as decisões que <br />                
                    marcarão o resto da sua existência na terra... Venha responder ao ULTIMATO
                </p>
            </div>
        </div>
    );
}