import Events from "../../components/Events/Events";
import Footer from "../../components/Footer/Footer";
import Menu from "../../components/Menu/Menu";
import "./PurchasePage.css";

export default function PurchasePage() {
    return (
        <>
            <Menu />
            <div className="purchase-page">
                <div className="container">
                    <h3>
                        Congresso de jovens ONE 2022 <br />
                        <span className="title">Ultimato</span><br />
                        <span className="date">25/02/2022 - 01/03/2022</span>
                    </h3>
                    <Events />
                    <p>
                        Em 2022 estamos chamando você para ser capacitado para tomar as decisões que <br />                
                        marcarão o resto da sua existência na terra... Venha responder ao ULTIMATO
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
}