
import imageVr from "../../assets/img/man-vr.png";
import "./Vr.css";

export default function Vr() {
    return (
        <div className="vr-content">
            <div className="container">
                <div className="banner">
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <img src={imageVr} />
                        </div>

                        <div className="col vr-text">
                            <div>
                                Você vai se sentir dentro do congresso com a nossa Realidade Virtual
                                <span className="vr-imersive">Experiência Imersiva</span>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}