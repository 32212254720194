import Menu from "../../components/Menu/Menu";
import LineUp from "../../components/LineUp/LineUp";
import About from "../../components/About/About";
import Purchase from "../../components/Purchase/Purchase";
import Footer from "../../components/Footer/Footer";
import Vr from "../../components/Vr/Vr";

export default function HomePage() {
  return (
    <>
      <Menu />
      <LineUp />
      <About />
      <Vr />
      <Purchase />
      <Footer />
    </>
  );
}