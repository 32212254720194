import Footer from "../../components/Footer/Footer";
import Menu from "../../components/Menu/Menu";
import "./Soon.css";

export default function Soon() {
    return (
        <>
            <Menu />
            <div className="soon-page">
                <div className="container">
                    <h3>
                        Mais informações<br />
                        <span className="title">Em breve</span><br />
                    </h3>                    
                </div>
            </div>
            <Footer />
        </>
    );
}