import "./About.css";
import hourglass from "../../assets/img/hourglass.png"

export default function About() {
    return (
        <div className="about">
            <div className="container vertical-center">
                <div className="row">
                    <div className="col hourglass">
                        <img src={hourglass} />
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <h3>
                            Sobre o congresso
                        </h3>
                        <p>Caminhamos pela estrada da vida. Algumas vezes corremos, em outras, andamos. Até que de repente nos encontramos diante de uma escolha. Qual caminho seguir? Sabemos que afetará toda nossa história.</p>
                        <p><strong>Não há como continuar sem uma DECISÃO.</strong></p>

                        <p>Decisão que vem forçada por circunstâncias que nos trazem um ULTIMATO.</p>

                        <p>Afinal, o que escolheremos? Não há como esperar. É a hora de decidir.</p>

                        <p>Não dá mais para esquivar nem adiar. Não dá mais para continuar como estávamos. Precisamos agir!</p>

                        <p>Os últimos anos têm sido uma mensagem clara de Deus para a humanidade. O tempo da mudança é AGORA.</p>

                        <p>Olhando para tudo que nos aconteceu não se pode negar que o Senhor tem nos avisado sobre os próximos capítulos da história da Igreja na terra.</p>

                        <p>Aqueles que ouvirem a voz do guia tomarão os passos certos.</p>

                        <p>Qual caminho você vai seguir? Não há tempo para esperar. Esse é o ULTIMATO.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}