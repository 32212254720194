import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import HomePage from './pages/Home/HomePage';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PurchasePage from './pages/PurchasePage/PurchasePage';
import Soon from './pages/Soon/Soon';

ReactDOM.render(
  <BrowserRouter>
    <Routes>      
      <Route path="/" element={<HomePage />} />
      <Route path="/purchase" element={<PurchasePage />} />
      <Route path="/soon" element={<Soon />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
